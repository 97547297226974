import { VehicleContactRole } from '../../types/vehicle-contact';
import { sleep, hasFlag } from '../../utils';
import { Loader } from '@googlemaps/js-api-loader';

class MapService {
  _map;
  _pointMarkers = new Set();
  _vehicleMarkers = new Map();

  constructor() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_TOKEN,
      version: '3.exp',
      language: 'en',
      region: 'US',
    });
    loader.load().catch(err => console.error(err));
  }

  initMap({ mapElement, theme }) {
    return new Promise(async resolve => {
      while (!window.hasOwnProperty('google')) {
        await sleep(100);
      }

      this._map = new google.maps.Map(mapElement, {
        disableDefaultUI: true,
        styles:
          theme === 'dark'
            ? [
                { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
                { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
                {
                  featureType: 'administrative.locality',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#d59563' }],
                },
                {
                  featureType: 'poi',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#d59563' }],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'geometry',
                  stylers: [{ color: '#263c3f' }],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#6b9a76' }],
                },
                {
                  featureType: 'road',
                  elementType: 'geometry',
                  stylers: [{ color: '#38414e' }],
                },
                {
                  featureType: 'road',
                  elementType: 'geometry.stroke',
                  stylers: [{ color: '#212a37' }],
                },
                {
                  featureType: 'road',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#9ca5b3' }],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry',
                  stylers: [{ color: '#746855' }],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry.stroke',
                  stylers: [{ color: '#1f2835' }],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#f3d19c' }],
                },
                {
                  featureType: 'transit',
                  elementType: 'geometry',
                  stylers: [{ color: '#2f3948' }],
                },
                {
                  featureType: 'transit.station',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#d59563' }],
                },
                {
                  featureType: 'water',
                  elementType: 'geometry',
                  stylers: [{ color: '#17263c' }],
                },
                {
                  featureType: 'water',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#515c6d' }],
                },
                {
                  featureType: 'water',
                  elementType: 'labels.text.stroke',
                  stylers: [{ color: '#17263c' }],
                },
              ]
            : [],
      });

      this._map.setCenter(new google.maps.LatLng(38.4493288, -99.7740464));
      this._map.setZoom(5);

      this._pointMarkers.forEach(marker => marker.setMap(this._map));
      this._vehicleMarkers.forEach(marker => marker.setMap(this._map));

      resolve();
    });
  }

  createPointMarker(position, radius) {
    this.clearPointMarkers();

    const pointMarker = new google.maps.Marker({
      title: 'point',
      position,
      map: this._map,
    });

    pointMarker['destroy'] = function() {
      pointMarker.setMap(null);
    };

    const circleMarker = new google.maps.Circle({
      strokeColor: '#ff0000',
      strokeOpacity: 0.4,
      strokeWeight: 2,
      fillColor: '#ff0000',
      fillOpacity: 0.2,
      map: this._map,
      center: position,
      radius: (radius | 0) * 1000 * 1.609,
    });

    circleMarker['destroy'] = function() {
      circleMarker.setMap(null);
    };

    this._pointMarkers.add(pointMarker);
    this._pointMarkers.add(circleMarker);
  }

  clearPointMarkers() {
    this._pointMarkers.forEach(marker => marker.destroy());
    this._pointMarkers.clear();
  }

  createVehicleMarker(vehicle, vehicleTypeName, vehicleTypeColor) {
    if (!vehicle?.location) {
      return;
    }

    const position = { lat: vehicle.location.lat, lng: vehicle.location.lng };

    const markerText =
      `Unit: ${vehicle.unit}\n` +
      `Owner: ${vehicle.contacts.find(i => hasFlag(i.roles, VehicleContactRole.OWNER))?.person?.name}\n` +
      `Type: ${vehicleTypeName}\n` +
      `Dimensions: ${vehicle.length}/${vehicle.width}/${vehicle.height} (${vehicle.doorWidth}/${vehicle.doorHeight})\n` +
      `Capacity: ${vehicle.capacity}\n` +
      `Notes: ${vehicle.notes || '-'}`;

    const icon = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 5.5,
      fillColor: vehicleTypeColor,
      fillOpacity: 1,
      strokeWeight: 0,
    };

    const marker = new google.maps.Marker({
      title: markerText,
      position: position,
      icon,
      map: this._map,
    });

    marker['destroy'] = function() {
      marker.setMap(null);
    };

    this._vehicleMarkers.set(vehicle.id, marker);
  }

  removeVehicleMarker(vehicle) {
    const marker = this._vehicleMarkers.get(vehicle.id);
    marker?.destroy();
    this._vehicleMarkers.delete(vehicle.id);
  }

  clearVehicleMarkers() {
    this._vehicleMarkers.forEach(marker => marker.destroy());
    this._vehicleMarkers.clear();
  }

  updateMarkers() {
    this._pointMarkers.forEach(marker => marker.setMap(this._map));
    this._vehicleMarkers.forEach(marker => marker.setMap(this._map));
  }
}

export const mapService = new MapService();
